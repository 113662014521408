import ScrollView from 'devextreme-react/scroll-view';
import React, { useRef } from 'react';
import { Header, Footer } from '../../components';
import './side-nav-outer-toolbar.scss';
import type { SideNavToolbarProps } from '../../types';

export default function SideNavOuterToolbar({
  title,
  children,
}: React.PropsWithChildren<SideNavToolbarProps>) {
  const scrollViewRef = useRef<ScrollView>(null);

  return (
    <div className={'side-nav-outer-toolbar'}>
      <Header title={title} menuToggleEnabled={false} />
      <div className={'container'}>
        <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
          <div className={'content'}>
            {React.Children.map(children, (item: any) => {
              return item.type !== Footer && item;
            })}
          </div>
          <div className={'content-block'}>
            {React.Children.map(children, (item: any) => {
              return item.type === Footer && item;
            })}
          </div>
        </ScrollView>
      </div>
    </div>
  );
}
