import './ExtractedSummary.scss';
import type { ExtractedSummaryProps } from '../../types';

export default function ExtractedSummary({
  title,
  summaryPreText,
  expandedTitle,
  setBoxExpanded,
}: ExtractedSummaryProps) {
  const expanded = expandedTitle === title;

  return (
    <div className="section-container">
      <div className="section-title" onClick={() => setBoxExpanded(expanded ? 'T' : title)}>
        {title}
      </div>
      {expanded && summaryPreText ? (
        <div className={'dx-card responsive-paddings-small'}>
            <div className={'pre-summary'} dangerouslySetInnerHTML={{ __html: summaryPreText }} />

        </div>
      ) : null}
    </div>
  );
}
