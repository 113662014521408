import './SelectorSection.scss';
import type { SelectorSectionProps } from '../../types';
import RecordSelectorCard from '../record-selector-card/RecordSelectorCard';

export default function SelectorSection({
  data,
  onSelectionChange,
  expandedTitle,
  setBoxExpanded,
}: SelectorSectionProps) {
  const expanded = expandedTitle === data.name;

  return (
    <div className="section-container">
      <div className="section-title" onClick={() => setBoxExpanded(expanded ? 'T' : data.name)}>
        {data.name}
      </div>
      {expanded && data?.components ? (
        <div className={'dx-card responsive-paddings-small'}>
          {data.components.map((item, index) => (
            <RecordSelectorCard
              key={item.name}
              data={item}
              onSelectionChange={(taskIndex, status) =>
                onSelectionChange(index, taskIndex, status)
              }
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}
