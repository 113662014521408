/* eslint-disable no-console */
// General api to access data
import { ApiConstants } from './ApiConstants';

export const fetchFromAPI: <ResponseType>(
  path: string,
  options: RequestInit,
  returnResponseStatus?: boolean,
) => Promise<ResponseType> = (path, options, returnResponseStatus = false) =>
  new Promise((resolve, reject) => {
    const customURL = ApiConstants.BASE_URL + path;

    if (ApiConstants._DEV_) {
      console.log(
        '\n******************* REQUEST ******************',
        '\nURL :',
        customURL,
        '\nMethod:',
        options.method,
        '\nHeader:',
        options.headers,
        '\nBody:',
        options.body,
        '\n*********************************',
      );
    }

    fetch(customURL, options)
      .then((response) => {
        if (returnResponseStatus) {
          return response.status;
        }
        return response.json();
      })
      .then((json) => {
        if (ApiConstants._DEV_) {
          console.log(
            '\n***************** RESPONSE ****************',
            '\nURL :',
            customURL,
            '\nMethod:',
            options.method,
            '\nBody:',
            options.body,
            '\nResponse:',
            JSON.stringify(json),
            '\n*********************************',
          );
        }
        resolve(json);
      })
      .catch((error) => {
        if (ApiConstants._DEV_) {
          console.log(
            '\n************** RESPONSE ERROR *************',
            '\nURL :',
            customURL,
            '\nResponse:',
            error,
            '\n*********************************',
          );
        }
        reject(error);
      });
  });
