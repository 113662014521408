import './PatientDetails.scss';
import type {PatientDetailsProps} from '../../types';

export default function PatientDetails({
                                           title,
                                           data,
                                           expandedTitle,
                                           setBoxExpanded,
                                       }: PatientDetailsProps) {
    const expanded = expandedTitle === title;

    return (
        <div className="section-container">
            <div className="section-title" onClick={() => setBoxExpanded(expanded ? 'T' : title)}>
                {title}
            </div>
            {expanded && data ? (
                <div className={'dx-card responsive-paddings-small'}>
                    <p>
                        <span className="label">Name:</span>{' '}
                        <span className="value">{data.name}</span>
                    </p>
                    <p>
                        <span className="label">NHS Number:</span>{' '}
                        <span className="value">{data.nhs}</span>
                    </p>
                    <p>
                        <span className="label">Address:</span>{' '}
                        <span className="value">{data.address}</span>
                    </p>
                    <p>
                        <span className="label">DOB:</span>{' '}
                        <span className="value">{data.dob}</span>
                    </p>
                </div>
            ) : null}
        </div>
    );
}
