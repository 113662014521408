import { fetchFromAPI } from '.';
import { BaseResponse, Token, User } from '../types';
import { ApiConstants } from './ApiConstants';

export async function signIn(email: string, password: string) {
  try {
    const authHeader = `${email}:${password}`;
    const response = await fetchFromAPI<Token>(
      'api/tokens',
      ApiConstants.getBasicAuthOptions(ApiConstants.METHOD_POST, authHeader),
    );

    if (response?.access_token) {
      localStorage.setItem('accessToken', response?.access_token);
      return {
        isOk: true,
        data: response,
      };
    } else {
      return {
        isOk: false,
        message: 'Authentication failed',
      };
    }
  } catch (e) {
    console.log(e);
    return {
      isOk: false,
      message: 'Authentication failed',
    };
  }
}

export async function getUser() {
  try {
    const response = await fetchFromAPI<User>(
      'api/me',
      ApiConstants.getOptions(ApiConstants.METHOD_GET),
    );

    if (response?.username) {
      localStorage.setItem('username', response?.username);
      return {
        isOk: true,
        data: response,
      };
    } else {
      return {
        isOk: false,
      };
    }
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    const response = await fetchFromAPI<User>(
      'api/users',
      ApiConstants.getOptionsWithoutAuth(ApiConstants.METHOD_POST, {
        username: email,
        email: email,
        password: password,
        about_me: '',
      }),
    );

    if (response?.username) {
      localStorage.setItem('username', response?.username);
      return {
        isOk: true,
        data: response,
      };
    } else {
      return {
        isOk: false,
        message: response?.description ?? 'Failed to create account',
      };
    }
  } catch {
    return {
      isOk: false,
      message: 'Failed to create account',
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to change password',
    };
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to reset password',
    };
  }
}

export function revokeToken() {
  try {
    fetchFromAPI<BaseResponse>(
      'api/tokens',
      ApiConstants.getOptions(ApiConstants.METHOD_DELETE),
      true
    )
      .then((resp) => {
        console.log({ resp });
      })
      .catch((e) => {
        console.log(e);
      });
  } catch (e) {
    console.log(e);
  }
}
