import React from 'react';
import './profile.scss';
import Form from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';
import moment from 'moment';

export default function Profile() {
  const { user } = useAuth();

  const profileDetails = {
    userID: user?.id,
    email: user?.email,
    username: user?.username,
    aboutMe: user?.about_me,
    imageURL: user?.avatar_url,
    firstLogin: moment(user?.first_seen).format('DD MMM, YYYY hh:mm A'),
    lastSeen: moment(user?.last_seen).format('DD MMM, YYYY hh:mm A'),
  };

  return (
    <React.Fragment>
      <h2 className={'content-block-profile'}>Profile</h2>

      <div className={'content-block-profile dx-card responsive-paddings'}>
        <div className={'form-avatar'}>
          <img alt={''} src={user?.avatar_url} />
        </div>
        <div className={'column'}>
          <span>{`${user?.username}`}</span>
          {user?.about_me ? <span>{`${user?.about_me}`}</span> : null}
        </div>
      </div>

      <div className={'content-block-profile dx-card responsive-paddings'}>
        <Form
          id={'form'}
          defaultFormData={profileDetails}
          labelLocation={'top'}
          colCountByScreen={colCountByScreen}
          readOnly
        />
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};
