import './RecordSelectorCard.scss';
import type { RecordSelectorCardProps } from '../../types';
import { CheckBox } from 'devextreme-react';

export default function RecordSelectorCard({
  data,
  onSelectionChange,
}: RecordSelectorCardProps) {
  return (
    <div className={`container-row ${data.bgColor}` }>
      {data.name ? <div className="title-text">{data.name}</div> : null}
      {data.tasks?.map((item, index) => (
        <CheckBox
          key={`${index}.${item.name}`}
          className="checkbox"
          iconSize={15}
          text={item.name}
          value={item.status === 'checked'}
          onValueChange={() => onSelectionChange(index, item.status)}
        />
      ))}
    </div>
  );
}
