import React, { createContext, useContext, useCallback } from 'react';
import type { ExtractorContextType, ExtractorRequest } from '../types';
import { extractFilesRequest } from '../api/extractor';
import { useAuth } from './auth';

const signOutOn401 = true;

function ExtractorProvider(props: React.PropsWithChildren<unknown>) {
  const { clearToken } = useAuth();
  const extractFiles = useCallback(async (params: ExtractorRequest) => {
    const result = await extractFilesRequest(params);
    console.log({ result });

    if (signOutOn401 && !result.isOk && result.data?.code === 401) {
      clearToken();
    }

    return result;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <ExtractorContext.Provider value={{ extractFiles }} {...props} />;
}

const ExtractorContext = createContext<ExtractorContextType>(
  {} as ExtractorContextType,
);
const useExtractor = () => useContext(ExtractorContext);

export { ExtractorProvider, useExtractor };
