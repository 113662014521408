import './ImageDetails.scss';
import type { ImageDetailsProps } from '../../types';

export default function ImageDetails({ data }: ImageDetailsProps) {
  const renderMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <div className="pre-text-content">
      {data?.results?.map((item, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: item.text }} />
      ))}
    </div>
  );
}
