import { Buffer } from 'buffer';

export const ApiConstants = {
  // BASE_URL: process.env.REACT_APP_BASE_URL ?? 'https://linsaapiv1.herokuapp.com/', // DEV
  BASE_URL: process.env.REACT_APP_BASE_URL ?? 'http://192.248.154.250:5000/', // local
  _DEV_: !process.env.NODE_ENV || process.env.NODE_ENV === 'development', // Is DEV env
  METHOD_GET: 'GET',
  METHOD_PUT: 'PUT',
  METHOD_POST: 'POST',
  METHOD_DELETE: 'DELETE',
  getOptions(method: string, params?: Object): RequestInit {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      credentials: 'include',
      method,
      ...(params && { body: JSON.stringify(params) }),
    };
  },
  getOptionsWithoutAuth(method: string, params?: Object): RequestInit {
    return {
      headers: {
        'Content-Type': 'application/json',
      },
      method,
      ...(params && { body: JSON.stringify(params) }),
    };
  },
  getBasicAuthOptions(method: string, authHeader: string): RequestInit {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${Buffer.from(authHeader).toString('base64')}`,
      },
      credentials: 'include',
      method,
    };
  },
};
