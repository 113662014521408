import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react';
import { getUser, revokeToken, signIn as sendSignInRequest } from '../api/auth';
import type { User, AuthContextType } from '../types';

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);

  const getUserDetails = async () => {
    const result = await getUser();
    if (result.isOk) {
      setUser(result.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getUserDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const signIn = useCallback(async (email: string, password: string) => {
    const result = await sendSignInRequest(email, password);
    console.log({ result });
    if (result?.data?.access_token) {
      getUserDetails();
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    revokeToken();
    localStorage.removeItem('accessToken');
    if (localStorage.getItem('rememberMe') !== 'ACTIVE') {
      localStorage.removeItem('username');
    }
    setUser(undefined);
  }, []);

  const clearToken = useCallback(() => {
    localStorage.removeItem('accessToken');
    setUser(undefined);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, clearToken, loading }}
      {...props}
    />
  );
}

const AuthContext = createContext<AuthContextType>({
  loading: false,
} as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
