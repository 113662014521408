import { fetchFromAPI } from '.';
import { ExtractorRequest, ExtractorResponse } from '../types';
import { ApiConstants } from './ApiConstants';

export async function extractFilesRequest(params: ExtractorRequest) {
  try {
    console.log(params)
    const response = await fetchFromAPI<ExtractorResponse>(
      'api/extractor',
      ApiConstants.getOptions(ApiConstants.METHOD_POST, params),
    );

    if (response?.code === 200) {
      return {
        isOk: true,
        data: response,
      };
    } else {
      return {
        isOk: false,
        data: response,
        message: response?.description ?? 'Failed to submit details',
      };
    }
  } catch {
    return {
      isOk: false,
      message: 'Failed to submit details',
    };
  }
}
