import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './UserPanel.scss';
import type { UserPanelProps } from '../../types';

export default function UserPanel({ menuMode }: UserPanelProps) {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  function navigateToHome() {
    navigate('/home');
  }

  function navigateToProfile() {
    navigate('/profile');
  }
  const menuItems = useMemo(
    () => [
      {
        text: 'Home',
        icon: 'home',
        onClick: navigateToHome,
      },
      {
        text: 'Profile',
        icon: 'user',
        onClick: navigateToProfile,
      },
      {
        text: 'Logout',
        icon: 'runner',
        onClick: signOut,
      },
    ],
    [signOut], // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div
            style={{
              background: `url(${user!.avatar_url}) no-repeat #fff`,
              backgroundSize: 'cover',
            }}
            className={'user-image'}
          />
        </div>
        <div
          className={user!.email?.length > 24 ? 'user-name-small' : 'user-name'}
        >
          {user!.email}
        </div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
