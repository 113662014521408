import React, { useEffect, useState } from 'react';
import './home.scss';
import {
  ExtractedSummary,
  ImageDetails,
  PatientDetails,
  SelectorSection,
} from '../../components';
import { Button } from 'devextreme-react/button';
import { useExtractor } from '../../contexts/extractor';
import { ExtractorRespData, ImageFileData } from '../../types';
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { FileUploader } from 'devextreme-react';
import { ValueChangedEvent } from 'devextreme/ui/file_uploader';

const appendNumberPrefixToFileName = false;

export default function Home() {
  const { extractFiles } = useExtractor();
  const [copiedImageURLs, setCopiedImageURLs] = useState<ImageFileData[]>([]);
  const [extractedData, setExtractedData] = useState({} as ExtractorRespData);
  const [fileType, setFileType] = useState('');
  const [pasteImageError, setPasteImageError] = useState('');
  const [expandedTitle, setExpandedTitle] = useState('Summary');
  const [pastingPage, setPastingPage] = useState(true);
  const [loading, setLoading] = useState(false);

  const blobToData = (blob: Blob) => {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  };

  const onSubmitClick = async () => {
    setLoading(true);
    const result = await extractFiles({
      fileName: 'string',
      fileType: fileType,
      files: copiedImageURLs,
    });
    setLoading(false);

    if (result.isOk) {
      setExtractedData(result.data?.data ?? ({} as ExtractorRespData));
      setPastingPage(false);
    } else {
      notify(result.message, 'error', 2000);
    }
  };

  const onDoneClick = () => {
    // Get all the tasks
    const allTasks = extractedData?.sections?.flatMap((section) =>
      section.components?.flatMap((component) => component.tasks),
    );

    // Check if all the task statuses are 'checked'
    const allTasksChecked =
      !allTasks ||
      allTasks.length === 0 ||
      allTasks.every((task) => !task || task.status === 'checked');

    if (allTasksChecked) {
      setFileType('');
      setCopiedImageURLs([]);
      setExtractedData({} as ExtractorRespData);
      setPasteImageError('');
      setPastingPage(true);
      setLoading(false);
    } else {
      notify('Please check and tick all the tasks to continue', 'error', 2000);
    }
  };

  const onRemoveImageClick = (imageName: string) => {
    setCopiedImageURLs((prevURLs) => {
      const temp: ImageFileData[] = [];
      prevURLs.forEach((image) => {
        if (image.name !== imageName) {
          temp.push(image);
        }
      });

      if (temp.length === 0) {
        setFileType('');
      }

      return temp;
    });
  };

  const handleTransformDataTransferIntoURL = async (
    dataTransfer: DataTransfer,
  ): Promise<ImageFileData[]> => {
    const temp: ImageFileData[] = [];

    let files: File[] = [];
    if (dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      //@ts-ignore
      files = [...dataTransfer.items].map((item) => item.getAsFile());
    } else {
      // Use DataTransfer interface to access the file(s)
      files.push(dataTransfer.files[0]);
    }

    for (let i = 0; i < files.length; i++) {
      const blob = files[i];
      if (blob) {
        try {
          const base64Data = await blobToData(blob);
          temp.push({ name: blob.name, data: base64Data });
        } catch (error) {
          setPasteImageError('Copying failed! Please check & try again...');
          console.log({ error });
          temp.push({ name: blob.name, data: '' });
        }
      } else {
        temp.push({ name: '', data: '' });
      }
    }

    return temp;
  };

  const showOnlyOneDocError = () => {
    notify(
      `You cannot submit more than one document at a time!`,
      'error',
      3000,
    );
  };

  const showNoDocsWithImagesError = () => {
    notify(`You cannot submit documents and images together!`, 'error', 3000);
  };

  const addURLToArray = (data: string, name: string) => {
    let isDocument = false;
    let addToList = true;

    if (name.endsWith('.docx') || name.endsWith('.doc')) {
      if (!fileType) {
        setFileType('DOC');
        isDocument = true;
      } else if (fileType === 'IMG') {
        addToList = false;
        showNoDocsWithImagesError();
      } else {
        addToList = false;
        showOnlyOneDocError();
      }
    } else if (name.endsWith('.pdf')) {
      if (!fileType) {
        setFileType('PDF');
        isDocument = true;
      } else if (fileType === 'IMG') {
        addToList = false;
        showNoDocsWithImagesError();
      } else {
        addToList = false;
        showOnlyOneDocError();
      }
    } else if (
      name.endsWith('.png') ||
      name.endsWith('.jpg') ||
      name.endsWith('.jpeg')
    ) {
      if (!fileType || fileType === 'IMG') {
        isDocument = false;
        setFileType('IMG');
      } else {
        addToList = false;
        showNoDocsWithImagesError();
      }
    } else {
      addToList = false;
      notify(
        `This file type is not supported! Please select a docx, pdf, png or jpg file to upload.`,
        'error',
        3000,
      );
    }

    if (addToList && data) {
      let itemAdded = true;

      setCopiedImageURLs((prevURLs) => {
        // Check whether image was already added.
        // const notOnList = prevURLs.every((image) => image.name !== name);
        const notOnList = true
        if (appendNumberPrefixToFileName) {
          return [
            ...prevURLs,
            {
              name: `${prevURLs.length + 1}.${name}`,
              data: data,
              isDocument,
            },
          ];
        } else if (notOnList) {
          return [...prevURLs, { name: name, data: data, isDocument }];
        } else {
          itemAdded = false;
          notify(`${name} was already added!`, 'error', 3000);
          return prevURLs;
        }
      });

      if (itemAdded) {
        runScrollerAnimation();
      }
    }
  };

  const onTaskClicked = (
    sectionIndex: number,
    componentIndex: number,
    taskIndex: number,
    status: string,
  ) => {
    const spanElement = document.getElementById(
      'span' + sectionIndex + componentIndex + taskIndex,
    );
    console.log(sectionIndex + ':' + componentIndex + ':' + taskIndex);
    if (spanElement) {
      if (status === 'checked') {
        spanElement.style.backgroundColor = 'white';
        // spanElement.style.color = 'black';
        // spanElement.classList.add("highlight-blue");
      } else {
        spanElement.style.backgroundColor = spanElement.title;
        // spanElement.style.color = 'white';
      }
    }
    setExtractedData((prevState) => {
      const newState = { ...prevState }; // create a copy of the state object
      newState.sections[sectionIndex].components[componentIndex].tasks[
        taskIndex
      ].status = status === 'checked' ? 'unchecked' : 'checked'; // update the value of the status property
      return newState; // return the updated state object
    });
  };

  const handleDrop = async (e: unknown) => {
    const event = e as DragEvent;
    setPasteImageError('');
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      if (event.dataTransfer && pastingPage) {
        const imageDataArr = await handleTransformDataTransferIntoURL(
          event.dataTransfer,
        );
        imageDataArr.forEach((item) => addURLToArray(item.data, item.name));
      }
    }
  };

  const onFileSelected = async (e: ValueChangedEvent) => {
    if (e?.value) {
      try {
        const blob = e.value[0];
        const base64Data = await blobToData(blob);
        addURLToArray(base64Data, blob.name);
      } catch (error) {
        console.log({ error });
      }
    }
  };

  const handleDrag = async (e: unknown) => {
    const event = e as DragEvent;
    setPasteImageError('');
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const runScrollerAnimation = () => {
    // Scroll to the bottom of the div with smooth effect
    setTimeout(() => {
      const uploadRightBox = document.querySelector('.upload-right-box');
      if (uploadRightBox) {
        const scrollDuration = 750; // Duration of the scrolling animation in milliseconds
        const start = uploadRightBox.scrollTop;
        const end = uploadRightBox.scrollHeight;
        const distance = end - start;
        const startTime = performance.now();

        const scrollAnimation = (currentTime: number) => {
          const elapsedTime = currentTime - startTime;
          const scrollPosition = easeInOutQuad(
            elapsedTime,
            start,
            distance,
            scrollDuration,
          );
          uploadRightBox.scrollTop = scrollPosition;

          if (elapsedTime < scrollDuration) {
            requestAnimationFrame(scrollAnimation);
          }
        };

        const easeInOutQuad = (
          t: number,
          b: number,
          c: number,
          d: number,
        ): number => {
          t /= d / 2;
          if (t < 1) return (c / 2) * t * t + b;
          t--;
          return (-c / 2) * (t * (t - 2) - 1) + b;
        };

        requestAnimationFrame(scrollAnimation);
      }
    }, 100);
  };

  useEffect(() => {
    const handlePasteOnDocument = async (e: ClipboardEvent) => {
      setPasteImageError('');
      if (e.clipboardData && pastingPage) {
        const imageDataArr = await handleTransformDataTransferIntoURL(
          e.clipboardData,
        );
        imageDataArr.forEach((item) => addURLToArray(item.data, item.name));
      }
    };

    const handleDropOnDocument = async (e: DragEvent) => {
      setPasteImageError('');
      if (e.dataTransfer && pastingPage) {
        const imageDataArr = await handleTransformDataTransferIntoURL(
          e.dataTransfer,
        );
        imageDataArr.forEach((item) => addURLToArray(item.data, item.name));
      }
    };

    document.addEventListener('paste', handlePasteOnDocument);
    document.addEventListener('drop', handleDropOnDocument);

    return () => {
      document.removeEventListener('paste', handlePasteOnDocument);
      document.removeEventListener('drop', handleDropOnDocument);
    };
  });

  if (pastingPage) {
    return (
      <React.Fragment>
        <div className={'top-container'}>
          <div className={'content-block-left upload-left-box'}>
            <div className="img-container">
              <div
                className="paste-box"
                onDrop={handleDrop}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
              >
                <i className="upload-icon dx-icon-parentfolder" />
                <div className="paste-text">Drag and Drop or Paste Images</div>
              </div>
            </div>
            {pasteImageError ? (
              <div className="paste-error-text">{pasteImageError}</div>
            ) : null}
            <div className="choose-file-btn">
              <FileUploader
                selectButtonText="Choose File"
                uploadMode="useForm"
                labelText=""
                multiple={false}
                allowedFileExtensions={[
                  '.pdf',
                  '.docx',
                  '.png',
                  '.jpg',
                  '.jpeg',
                ]}
                showFileList={false}
                onValueChanged={onFileSelected}
              />
            </div>
          </div>
          {copiedImageURLs?.length > 0 ? (
            <div className="content-block-right upload-right-box responsive-paddings">
              {copiedImageURLs.map((image, index) => (
                <div key={`${index}.${image.name}`} className="pasted-images">
                  {image.isDocument ? (
                    <div className="doc-name-text">{image.name}</div>
                  ) : (
                    <img className="paste-image" src={image.data} alt="PASTE" />
                  )}
                  <i
                    className="remove-icon dx-icon-remove"
                    onClick={() => onRemoveImageClick(image.name)}
                  />
                </div>
              ))}
              {loading ? (
                <div className="loader-wrapper">
                  <LoadIndicator
                    width={'24px'}
                    height={'24px'}
                    visible={true}
                  />
                </div>
              ) : (
                <div className="submit-btn">
                  <Button
                    className="submit-btn-content"
                    hoverStateEnabled={false}
                    text="SUBMIT"
                    onClick={onSubmitClick}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={'top-container'}>
        <div className={'content-block-left left-box'}>
          <PatientDetails
            title="Patient Details"
            data={extractedData?.patient}
            expandedTitle={expandedTitle}
            setBoxExpanded={setExpandedTitle}
          />
          <ExtractedSummary
            title="Summary"
            summaryPreText={extractedData?.summary}
            expandedTitle={expandedTitle}
            setBoxExpanded={setExpandedTitle}
          />
          {extractedData?.sections?.map((item, index) => (
            <SelectorSection
              key={`${index}.${item.name}`}
              data={item}
              onSelectionChange={(componentIndex, taskIndex, status) =>
                onTaskClicked(index, componentIndex, taskIndex, status)
              }
              expandedTitle={expandedTitle}
              setBoxExpanded={setExpandedTitle}
            />
          ))}
          {copiedImageURLs?.length > 0 && !loading ? (
            <div className="done-btn">
              <Button
                className="done-btn-content"
                hoverStateEnabled={false}
                text="DONE"
                onClick={onDoneClick}
              />
            </div>
          ) : null}
        </div>
        <div className="content-block-right right-box dx-card responsive-paddings">
          {extractedData?.file_data?.length > 0 ? (
            <ImageDetails data={extractedData?.file_data[0]} />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
}
